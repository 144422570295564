// csv.service.ts
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
declare const google: any;

@Injectable({
  providedIn: "root",
})
export class CsvService {
  exclusive = new Subject<boolean>();
  buttonvalue = new Subject<string>();
  showDeleteButton = new Subject<boolean>();
  changeLabel = new Subject<boolean>();
  showCancelBtn = new Subject<boolean>();
  changeEditButtonName = new Subject<string>();
  showSliderDeleteBtn = new Subject<boolean>();
  opensettingModal = new Subject<boolean>();
  openCDModal = new Subject<boolean>();
  showAddressonmap = new Subject<any>();
  calculateDistance = new Subject<any>();
  markerPattern = "https://maps.google.com/mapfiles/ms/icons/red-dot.png";
  markerColor = "red";
  markerType: string = "red-dot";
  setlng: any = -96.69094;
  setlat: any = 33.006557;
  locationId: any = 16;
  selectedShape: any;
  degree: -10;
  isSidebarMinimize: string;
  constructor() {}

  downloadCsv(data: any, filename: string): void {
    const csvContent = this.convertArrayToCsv(data);
    const blob = new Blob([csvContent], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  }

  private convertArrayToCsv(data: any[]): string {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));

    return `${header}\n${rows.join("\n")}`;
  }
  setSidebarValue(value: string) {
    this.isSidebarMinimize = value;
  }
  getSidebarValue() {
    return this.isSidebarMinimize;
  }
  public calculateDistancebetweenlocation(
    fromAddress: string,
    toAddress: string
  ): Observable<number> {
    const geocoder = new google.maps.Geocoder();
    return new Observable<any>((observer) => {
      geocoder.geocode({ address: fromAddress }, (fromResults, fromStatus) => {
        if (fromStatus === "OK" && fromResults.length > 0) {
          const fromLocation = fromResults[0].geometry.location;
          geocoder.geocode({ address: toAddress }, (toResults, toStatus) => {
            if (toStatus === "OK" && toResults.length > 0) {
              const toLocation = toResults[0].geometry.location;
              const distance =
                google.maps.geometry.spherical.computeDistanceBetween(
                  fromLocation,
                  toLocation
                );
              let distanceloca = {
                fromLocation: fromLocation,
                toLocation: toLocation,
                distance: distance,
              };
              observer.next(distanceloca);
              observer.complete();
            } else {
              observer.error(
                `Geocoding failed for ${toAddress} with status: ${toStatus}`
              );
            }
          });
        } else {
          observer.error(
            `Geocoding failed for ${fromAddress} with status: ${fromStatus}`
          );
        }
      });
    });
  }
}
