import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SidebarService {
  apiUrl = environment.baseUrl;
  constructor(private http: HttpClient) {}

  getMenuList() {
    return this.http.get(this.apiUrl + "menu/getSideBar");
    // http://localhost:8080/atlyms/menu/getSideBar
  }
}
