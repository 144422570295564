import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  apiUrl = environment.baseUrl;
  loginToken: string;
  newRefreshToken: string;

  constructor(private http: HttpClient, private router: Router) {}

  logIn(postData) {
    return this.http.post(this.apiUrl + "user/userLogin", postData);
  }
  getUserName() {
    return sessionStorage.getItem("userNameId");
  }
  setToken(token: any) {
    sessionStorage.setItem("token", token);
  }
  getToken() {
    return sessionStorage.getItem("token");
  }
  setRefreshToken(refreshToken: any) {
    sessionStorage.setItem("refreshToken", refreshToken);
  }
  getRefreshToken() {
    return sessionStorage.getItem("refreshToken");
  }
  isUserLoggedIn() {
    return !!sessionStorage.getItem("token");
  }

  sendOTP(sendOTPData) {
    return this.http.post(this.apiUrl + "user/sendOTP", sendOTPData);
  }
  verifyOTP(verifyOTPData) {
    return this.http.post(this.apiUrl + "user/verifyOTP", verifyOTPData);
  }
  changePassword(changePasswordData) {
    return this.http.put(
      this.apiUrl + "user/passwordUpdate",
      changePasswordData
    );
  }
  onLogOut() {
    this.router.navigateByUrl("login");
    sessionStorage.clear();
  }

  public updateAccessToken(token) {
    sessionStorage.removeItem("token");
    sessionStorage.setItem("token", token);
  }
  public updateRefreshToken(token) {
    sessionStorage.removeItem("refreshToken");
    sessionStorage.setItem("refreshToken", token);
  }
  getDataAsPerSiteId(siteId: any, userId: any) {
    let defaultParams = new HttpParams()
      .set("siteId", siteId)
      .set("userId", userId);

    return this.http.get(this.apiUrl + "user/getSite", {
      params: defaultParams,
    });
  }
}
