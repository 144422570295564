import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
  Directive,
} from "@angular/core";
import { ROUTES } from "../.././sidebar/sidebar.component";
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
} from "@angular/router";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import Swal from "sweetalert2";
import { SharedService } from "src/app/services/shared.service";
import { environment } from "src/environments/environment";
import { CsvService } from "src/app/services/Csvv.service";
import { DistancecalculatorDialogComponent } from "src/app/pages/maps/distancecalculator-dialog/distancecalculator-dialog.component";
import { SettingDialogComponent } from "src/app/pages/maps/setting-dialog/setting-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "src/app/Authentication/auth.service";
import { FormControl, FormGroup } from "@angular/forms";
const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};

declare var $: any;
@Component({
  selector: "app-navbar-cmp",
  templateUrl: "navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  todaysDate: Date = new Date();
  private listTitles: any[];
  location: Location;
  mobile_menu_visible: any = 0;
  private nativeElement: Node;
  private toggleButton: any;
  private sidebarVisible: boolean;
  showCancelBtn = false;
  exclusive: boolean = false;
  private _router: Subscription;
  baseUrlImg = environment.baseUrlImage;
  editButtonName = "Edit";
  showSliderDeleteBtn = false;
  siteId = [];
  siteData = [];
  @ViewChild("app-navbar-cmp", { static: false }) button: any;

  constructor(
    location: Location,
    private renderer: Renderer2,
    private element: ElementRef,
    private router: Router,
    private sharedServices: SharedService,
    private csvService: CsvService,
    public dialog: MatDialog,
    private authService: AuthService
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }
  minimizeSidebar() {
    const body = document.getElementsByTagName("body")[0];
    sessionStorage.setItem("IsminimizeSidebar", misc.sidebar_mini_active);
    this.sharedServices.setMiniSidebarStatus(misc.sidebar_mini_active);
    if (misc.sidebar_mini_active === true) {
      body.classList.remove("sidebar-mini");
      misc.sidebar_mini_active = false;
    } else {
      setTimeout(function () {
        body.classList.add("sidebar-mini");

        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  navbarForm = new FormGroup({
    siteIdValue: new FormControl(""),
  });

  hideSidebar() {
    const body = document.getElementsByTagName("body")[0];
    const sidebar = document.getElementsByClassName("sidebar")[0];

    if (misc.hide_sidebar_active === true) {
      setTimeout(function () {
        body.classList.remove("hide-sidebar");
        misc.hide_sidebar_active = false;
      }, 300);
      setTimeout(function () {
        sidebar.classList.remove("animation");
      }, 600);
      sidebar.classList.add("animation");
    } else {
      setTimeout(function () {
        body.classList.add("hide-sidebar");
        // $('.sidebar').addClass('animation');
        misc.hide_sidebar_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  ngOnInit() {
    this.csvService.exclusive.subscribe((res: boolean) => {
      this.exclusive = res;
    });
    this.csvService.showDeleteButton.subscribe((res: boolean) => {
      this.showSliderDeleteBtn = res;
    });
    this.csvService.showCancelBtn.subscribe((res: boolean) => {
      this.showCancelBtn = res;
    });
    this.csvService.changeEditButtonName.subscribe((res: string) => {
      this.editButtonName = res;
    });

    this.listTitles = ROUTES.filter((listTitle) => listTitle);

    const navbar: HTMLElement = this.element.nativeElement;
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    if (body.classList.contains("sidebar-mini")) {
      misc.sidebar_mini_active = true;
    }
    if (body.classList.contains("hide-sidebar")) {
      misc.hide_sidebar_active = true;
    }
    this._router = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.sidebarClose();

        const $layer = document.getElementsByClassName("close-layer")[0];
        if ($layer) {
          $layer.remove();
        }
      });

    let siteData = [];
    this.siteData = JSON.parse(sessionStorage.getItem("siteDetails"));
    siteData = JSON.parse(sessionStorage.getItem("siteDetails"));
    console.log(
      'sessionStorage.getItem("siteId"),sessionStorage.getItem("siteId")',
      sessionStorage.getItem("siteId")
    );
    this.siteId = siteData.map((x) => {
      return {
        siteCode: x.code,
      };
    });
    console.log("this.siteId", this.siteId);
    let data = siteData.filter((x) => {
      if (x.id == sessionStorage.getItem("siteId")) {
        return x;
      }
    });
    console.log("data", data);

    this.navbarForm.controls["siteIdValue"].setValue(data[0].code);
    console.log("siteData[0].id", siteData[0].id);
  }
  onResize(event) {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }
  sidebarOpen() {
    var $toggle = document.getElementsByClassName("navbar-toggler")[0];
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName("body")[0];
    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);
    body.classList.add("nav-open");
    setTimeout(function () {
      $toggle.classList.add("toggled");
    }, 430);

    var $layer = document.createElement("div");
    $layer.setAttribute("class", "close-layer");

    if (body.querySelectorAll(".main-panel")) {
      document.getElementsByClassName("main-panel")[0].appendChild($layer);
    } else if (body.classList.contains("off-canvas-sidebar")) {
      document
        .getElementsByClassName("wrapper-full-page")[0]
        .appendChild($layer);
    }

    setTimeout(function () {
      $layer.classList.add("visible");
    }, 100);

    $layer.onclick = function () {
      //asign a function
      body.classList.remove("nav-open");
      this.mobile_menu_visible = 0;
      this.sidebarVisible = false;

      $layer.classList.remove("visible");
      setTimeout(function () {
        $layer.remove();
        $toggle.classList.remove("toggled");
      }, 400);
    }.bind(this);

    body.classList.add("nav-open");
    this.mobile_menu_visible = 1;
    this.sidebarVisible = true;
  }
  sidebarClose() {
    var $toggle = document.getElementsByClassName("navbar-toggler")[0];
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton.classList.remove("toggled");
    var $layer = document.createElement("div");
    $layer.setAttribute("class", "close-layer");

    this.sidebarVisible = false;
    body.classList.remove("nav-open");
    // $('html').removeClass('nav-open');
    body.classList.remove("nav-open");
    if ($layer) {
      $layer.remove();
    }

    setTimeout(function () {
      $toggle.classList.remove("toggled");
    }, 400);

    this.mobile_menu_visible = 0;
  }
  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }
    for (let i = 0; i < this.listTitles.length; i++) {
      if (
        this.listTitles[i].type === "link" &&
        this.listTitles[i].path === titlee
      ) {
        return this.listTitles[i].title;
      } else if (this.listTitles[i].type === "sub") {
        for (let j = 0; j < this.listTitles[i].children.length; j++) {
          let subtitle =
            this.listTitles[i].path + "/" + this.listTitles[i].children[j].path;
          // console.log(subtitle)
          // console.log(titlee)
          if (subtitle === titlee) {
            return this.listTitles[i].children[j].title;
          }
        }
      }
    }
    return "Dashboard";
  }
  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }
  onChangeSiteId(id: any) {
    let findId = this.siteData.filter((x) => {
      if (x.code === id) {
        return x.id;
      }
    });
    let userId = sessionStorage.getItem("userId");
    console.log("findId", findId);
    this.sharedServices.showLoader();
    this.authService
      .getDataAsPerSiteId(findId[0].id, userId)
      .subscribe((res: any) => {
        console.log("onChangeSiteId", res);
        if (res.metadata.code === "200") {
          this.sharedServices.hideLoader();

          console.log("token=====>", res.result.accessToken);

          // sessionStorage.removeItem("token");
          sessionStorage.setItem("token", res.result.accessToken);
          // sessionStorage.removeItem("refreshToken");

          sessionStorage.setItem("refreshToken", res.result.refreshToken);

          sessionStorage.setItem("siteId", res.result.sites.id);

          sessionStorage.setItem("orgId", res.result.sites.organizationId.id);
          sessionStorage.setItem("CompId", res.result.sites.companyId.id);

          location.reload();
        }
      });
  }
  onLogOut() {
    Swal.fire({
      title: "Are you sure that you want to Log Out?",
      // text: "You want to log out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#31304d",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log out!",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        this.router.navigateByUrl("login");
      }
    });
  }

  onEdit(event: Event) {
    event.preventDefault(); // Prevents the default form submission behavior
    this.csvService.buttonvalue.next("edit");
  }
  onCancel(event: Event) {
    event.preventDefault(); // Prevents the default form submission behavior
    this.csvService.buttonvalue.next("onCancel");
  }
  onRoted(degree) {
    this.csvService.degree = degree;
    // event.preventDefault(); // Prevents the default form submission behavior
    this.csvService.buttonvalue.next("onRoted");
  }
  downloadCsv(event: Event) {
    event.preventDefault(); // Prevents the default form submission behavior
    this.csvService.buttonvalue.next("downloadCsv");
  }
  showAlert(event: Event) {
    event.preventDefault(); // Prevents the default form submission behavior
    this.csvService.buttonvalue.next("showAlert");
  }
  toggleMapType(event: Event) {
    event.preventDefault(); // Prevents the default form submission behavior
    this.csvService.buttonvalue.next("toggleMapType");
  }
  onEditLabel() {
    this.csvService.changeLabel.next(true);
  }
  calDistance() {
    this.dialog.open(DistancecalculatorDialogComponent, {
      disableClose: true,
    });

    // this.csvService.openCDModal.next(true);
  }
  markerSetting() {
    this.dialog.open(SettingDialogComponent, {
      disableClose: true,
    });

    // this.csvService.opensettingModal.next(true);
  }
}
