<h1 mat-dialog-title>Calculate Distance</h1>
<div mat-dialog-content>
  <div>
    <mat-form-field>
      <mat-label>From Address</mat-label>
      <input matInput [(ngModel)]="fromAddress" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>To Address</mat-label>
      <input matInput [(ngModel)]="toAddress" />
    </mat-form-field>
    <div class="distance-result">
      <p *ngIf="distance">Distance: {{ distance }} miles</p>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button
    type="button"
    class="btn btn-rose btn btn-primary btn-sm"
    (click)="calculateDistance()"
  >
    Calculate
  </button>
  <button
    type="button"
    class="btn btn-default btn btn-primary btn-sm"
    (click)="onCancel()"
  >
    Cancel
  </button>
</div>
