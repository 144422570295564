// setting-dialog.component.ts
import {
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
  OnInit,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CsvService } from "src/app/services/Csvv.service";

interface Option {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-setting-dialog",
  templateUrl: "./setting-dialog.component.html",
  styleUrls: ["./setting-dialog.component.css"],
})
export class SettingDialogComponent implements OnInit {
  selectedColor: string;
  selectedPattern: string;

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SettingDialogComponent>,
    private csvService: CsvService
  ) {}

  ngOnInit(): void {}

  colors: Option[] = [
    { value: "red", viewValue: "Red" },
    { value: "green", viewValue: "Green" },
    { value: "blue", viewValue: "Blue" },
    { value: "yellow", viewValue: "Yellow" },
  ];

  patterns: Option[] = [
    { value: "bubble", viewValue: "Bubble" },
    { value: "pin", viewValue: "Pin" },
    { value: "hospital", viewValue: "Hospital" },
    { value: "school", viewValue: "School" },
  ];

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    // if (this.data.From != "Territory Table") {
    this.csvService.markerColor = this.selectedColor;
    this.csvService.markerType = this.selectedPattern;
    if (this.selectedPattern === "hospital")
      this.csvService.markerPattern = "../../../assets/img/hospital.svg";
    else if (this.selectedPattern === "school")
      this.csvService.markerPattern = "../../../assets/img/school.png";
    else if (this.selectedPattern === "pin")
      this.csvService.markerPattern = "../../../assets/img/pin.png";
    else if (this.selectedPattern === "bubble")
      this.csvService.markerPattern = "../../../assets/img/bubbles.png";

    this.dialogRef.close();
    // } else {
    //   this.dialogRef.close(this.selectedColor);
    // }
  }
}
