<!-- <i (click)="minimizeSidebar()">arrow_left</i> -->

<!-- <div
  class="user"
  style="display: flex; flex-direction: row; justify-content: space-between"
>
  <!-- *ngIf="!miniSidebar" -->
<!-- <div style="display: flex; flex-direction: row">
    <div class="photo">
      <img src="./assets/img/faces/Untitled design.png" /> -->
<!--   (click)="minimizeSidebar()" -->
<!-- </div> -->
<!-- <div class="userInfo">
      <p>
        <span>{{ userName }}</span>
      </p>
    </div> -->
<!-- </div> -->

<!-- <mat-icon
    (click)="minimizeSidebar()"
    class="material-symbols-outlined"
    style="
      color: white;
      font-size: xx-large;
      justify-content: end;
      margin-right: 2%;
    "
    >menu</mat-icon
  > -->
<!-- </div> -->
<div class="user">
  <div class="photo">
    <img src="./assets/img/faces/Untitled design.png" />
  </div>
  <div class="userInfo">
    <p style="color: black">
      <span>{{ userName }}</span>
    </p>
  </div>
</div>
<div class="logo">
  <a class="simple-text logo-mini">
    <div class="logo-img">
      <img
        [src]="baseUrlImage + 'assets/img/icons/rfidLogo.png'"
        style="width: 42px"
      />
    </div>
  </a>
  <a class="simple-text logo-normal" style="color: black">RFID Inventory</a>
</div>

<div class="sidebar-wrapper">
  <div *ngIf="isMobileMenu()">
    <form class="navbar-form">
      <span class="bmd-form-group">
        <div class="userMini">
          <div class="photo">
            <img
              [src]="baseUrlImage + 'assets/img/faces/Untitled design.png'"
            />
          </div>

          <div class="userInfo">
            <p>
              <span>{{ userName }}</span>
            </p>
          </div>
        </div>
        <!-- <div class="input-group no-border">
          <button
            style="
              border: 0px solid #e6e9ec;
              padding: 0px 10px;
              margin-left: 5%;
            "
          >
            <input
              type="text"
              value=""
              class="form-control"
              placeholder="Search..."
              style="color: #b0bac3"
            />
          </button>
        </div> -->
      </span>
      <!-- <br />
      <div class="input-group no-border" style="margin-left: 3%">
        <button
          mat-raised-button
          style="border: 0px solid #e6e9ec; padding: 0px 10px; margin-left: 2%"
        >
          <input
            type="date"
            value=""
            class="form-control"
            style="color: #b0bac3"
          />
        </button>
      </div> -->
    </form>
    <!-- <ul class="nav navbar-nav nav-mobile-menu">
      <li class="nav-item">
        <a class="nav-link" href="#pablo">
          <i class="material-icons">dashboard</i>
          <p>
            <span class="d-lg-none d-md-block">Stats</span>
          </p>
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" href="#pablo">
          <i class="material-icons">person</i>
          <p>
            <span class="d-lg-none d-md-block">Account</span>
          </p>
        </a>
      </li>
    </ul> -->
    <button
      mat-raised-button
      class="btn btn-primary"
      style="margin-left: 11%"
      (click)="onLogOut()"
    >
      Logout
    </button>
    <br />
  </div>
  <ul class="nav">
    <li
      routerLinkActive="active"
      *ngFor="let menuitem of menuItems"
      class="nav-item"
    >
      <!--If is a single link-->
      <a
        [routerLink]="[menuitem.path]"
        *ngIf="menuitem.type === 'link'"
        class="nav-link"
        style="display: flex"
      >
        <!-- <i class="material-icons">{{ menuitem.icontype }}</i> -->
        <div
          style="
            display: flex;
            background-color: #31304d;
            height: 34px;
            width: 36px;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            margin-right: 10%;
          "
        >
          <img
            [src]="'./assets/img/icons/' + menuitem.icontype"
            style="width: 28px; height: 26px"
          />
        </div>

        <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="white">
          <path
            d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm4.188 15.304a.996.996 0 01-1.406.063l-5.125-4.625a.998.998 0 01-.031-1.438l5.156-6a1 1 0 111.5 1.312l-4.906 5.625 4.812 4.312c.195.175.305.418.305.687z"
          />
        </svg> -->

        <p>{{ menuitem.title }}</p>
      </a>
      <!--If it have a submenu-->
      <a
        data-toggle="collapse"
        href="#{{ menuitem.collapse }}"
        id="{{ menuitem.collapse }}-p"
        *ngIf="menuitem.type === 'sub'"
        (click)="updatePS(); expandOrCollapseMenu(menuitem.collapse)"
        class="nav-link"
        style="display: flex"
      >
        <!-- <i class="material-icons">{{ menuitem.icontype }}</i> -->
        <div
          style="
            display: flex;
            background-color: #31304d;
            height: 34px;
            width: 36px;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            margin-right: 10%;
          "
        >
          <img
            [src]="'./assets/img/icons/' + menuitem.icontype"
            style="width: 28px; height: 26px"
          />
        </div>
        <!-- <i class="fa-solid fa-warehouse"></i> -->
        <p>
          {{ menuitem.title }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <b class="caret"></b>
        </p>
      </a>

      <!--If it have a subChildmenu-->

      <!--Display the submenu items-->

      <div
        id="{{ menuitem.collapse }}"
        class="collapse"
        *ngIf="menuitem.type === 'sub'"
      >
        <ul class="nav" style="margin-left: 15%">
          <li
            routerLinkActive="active"
            *ngFor="let childitem of menuitem.children"
            class="nav-item"
          >
            <a
              [routerLink]="[menuitem.path, childitem.path]"
              class="nav-link"
              *ngIf="childitem.type == ''"
            >
              <i class="material-icons sidebar-subChildIcon"
                >radio_button_unchecked</i
              >
              <span class="sidebar-normal">{{ childitem.title }} </span>
            </a>
          </li>

          <div>
            <li
              routerLinkActive="active"
              *ngFor="let subChildMenu of menuitem.children"
              class="nav-item"
            >
              <a
                data-toggle="collapse"
                href="#{{ subChildMenu.collapse }}"
                id="{{ subChildMenu.collapse }}-p"
                *ngIf="subChildMenu.type === 'subChild'"
                (click)="
                  updatePS(); expandOrCollapseMenu(subChildMenu.collapse)
                "
                class="nav-link"
              >
                <i class="material-icons">{{ subChildMenu.icontype }}</i>
                <p>{{ subChildMenu.title }}<b class="caret"></b></p>
              </a>
              <div
                id="{{ subChildMenu.collapse }}"
                class="collapse"
                *ngIf="subChildMenu.type === 'subChild'"
              >
                <ul class="nav" style="margin-left: 10%">
                  <li
                    routerLinkActive="active"
                    *ngFor="let subChildItem of subChildMenu.childrens"
                    class="nav-item"
                  >
                    <a
                      [routerLink]="[subChildMenu.path, subChildItem.path]"
                      class="nav-link"
                    >
                      <i class="material-icons" style="color: black"
                        >radio_button_unchecked</i
                      >
                      <span class="sidebar-normal"
                        >{{ subChildItem.title }}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </li>

    <!-- <button mat-raised-button class="btn btn-info" style="margin-left: 11%">
      Logout
    </button> -->

    <!-- <div
      class="row"
      style="margin-left: 11%; cursor: pointer; margin-top: 1%"
      (click)="onLogout()"
    >
      <i class="material-icons" style="color: white">logout</i>
      <p style="color: white; font-size: medium">Logout</p>
    </div> -->
  </ul>
</div>
