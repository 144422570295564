import { Injectable } from "@angular/core";
import { ICON_REGISTRY_PROVIDER } from "@angular/material/icon";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  constructor(private router: Router) {}

  alert(message: string, type: any) {
    Swal.fire({
      title: message,
      icon: type,
      allowOutsideClick: false,
      confirmButtonColor: "#2f80ed",
    });
  }
  rfidPopUp(message: string) {
    Swal.fire({
      html: message,
    });
  }
  alertReload(message: string, type: any, path: string) {
    Swal.fire({
      title: message,
      icon: type,
      allowOutsideClick: false,
      confirmButtonColor: "#2f80ed",
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the page
        location.reload();
        // Redirect to a new page (change the URL)
        this.router.navigateByUrl(path);
      }
    });
  }
  alertReloadForAPt(message: string, type: any, path: string) {
    Swal.fire({
      title: message,
      icon: type,
      allowOutsideClick: false,
      confirmButtonColor: "#2f80ed",
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the page
        // location.reload();
        // Redirect to a new page (change the URL)
        this.router.navigateByUrl(path);
      }
    });
  }

  reload(message: string, type: any) {
    Swal.fire({
      title: message,
      icon: type,
      allowOutsideClick: false,
      confirmButtonColor: "#2f80ed",
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the page
        location.reload();
        // Redirect to a new page (change the URL)
      }
    });
  }

  alertWarning(message) {
    Swal.fire({
      title: message,
      icon: "error",
      iconHtml:
        '<mat-icon aria-label="Delete" style="color: red">warning</mat-icon>',
      allowOutsideClick: false,
      confirmButtonColor: "#2f80ed",
    });
  }
}
