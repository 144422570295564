import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { LoaderComponent } from "../shared/loader/loader.component";
import * as XLSX from "xlsx";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  private dialogRef!: MatDialogRef<LoaderComponent>;
  sidebarStatus: string;
  constructor(private dialog: MatDialog) {}
  private sharedData: any;

  setSharedData(data: any): void {
    this.sharedData = data;
  }

  getSharedData(): any {
    return this.sharedData;
  }
  showLoader(): void {
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(LoaderComponent, {
        disableClose: true,
        panelClass: "loader-dialog-overlay",
      });
    }
  }

  hideLoader(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = null as any;
    }
  }

  exportToExcel(data: any[], fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, fileName + ".xlsx");
  }
  getSiteId() {
    return sessionStorage.getItem("siteId");
  }
  getMiniSidebarStatus() {
    return this.getMiniSidebarStatus;
  }
  setMiniSidebarStatus(flag) {
    this.getMiniSidebarStatus = flag;
  }
}
